import { Box, Button, FormHelperText, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import './ManageAccount.scss'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import FormTextField from '../SignUp/FormTextField'
import { useSelector } from 'react-redux'
import { selectAuth } from '../selectors'
import { validateEmail, validateName } from '../utils'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import { BACK_BTN_MARGIN_TOP } from '../../core/utils/constants'

interface AccountDetailsEditProps {
  name: string
  email: string
  handleCancelClick: () => void
  handleUpdateInfoSubmission: (data: EditAccountInfoParams) => void
  isDrawerView: boolean
}

const AccountDetailsEdit = ({
  name,
  email,
  handleCancelClick,
  handleUpdateInfoSubmission,
  isDrawerView
}: AccountDetailsEditProps): ReactElement => {
  const i18t = useTranslation()
  const { t } = useTranslation()
  const initialValues: EditAccountInfoParams = { name, email }
  const authState = useSelector(selectAuth)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData: EditAccountInfoParams) => {
      handleUpdateInfoSubmission(formData)
    },
    validate: (values) => {
      let errors: { [key: string]: string } = {}
      const nameWithoutSpaces = values.name != null ? values.name.split(/ +/).join('') : ''
      errors = validateEmail(values.email, errors, i18t)
      errors = validateName(values.name, errors, nameWithoutSpaces, i18t)
      return errors
    },
    validateOnChange: false
  })

  const errorDisplay = (
    <FormHelperText error>{authState.editAccountInfoErr}</FormHelperText>
  )

  return (
    <Box
      className={`account-details-box ${!isDrawerView ? 'maxw-30' : ''}`}
      style={{ marginTop: matchesMd ? BACK_BTN_MARGIN_TOP : '' }}
    >
      {!isDrawerView && (
        <Typography className='title'>{t`MyAccount`}</Typography>
      )}
      <form onSubmit={form.handleSubmit}>
        <Box className='accounts-details-field'>
          <FormTextField
            id='name'
            name='name'
            value={form.values.name}
            label={t`Name`}
            errorField={form.errors.name}
            onChange={form.handleChange}
          />
          <FormTextField
            id='email'
            name='email'
            value={form.values.email}
            label={t`EmailSignIn`}
            errorField={form.errors.email}
            onChange={form.handleChange}
          />
          <Box
            className={`${
              !isDrawerView ? 'actions-box gap-0' : 'actions-box-drawer-view'
            }`}
          >
            <Box className='btn-box'>
              <Button className='btn' type='submit'>
                {t`UpdateInformation`}
              </Button>
            </Box>
            <Button
              className={`secondary-btn ${
                isDrawerView ? 'align-center' : ''
              }`}
              onClick={handleCancelClick}
            >
              {t`Cancel`}
            </Button>
          </Box>
        </Box>
        {authState.editAccountInfoErr != null &&
          authState.editAccountInfoErr !== '' &&
          errorDisplay}
      </form>
    </Box>
  )
}

export default AccountDetailsEdit
