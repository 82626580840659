import { createSelector } from 'reselect'
import { sortBy } from 'lodash'
import { ProviderStatusType } from './providerStatusType'

export const selectProviderList = (state: ApplicationState): ProviderAccount[] => sortBy(state.provider.providerList, 'UiPosition')

export const selectProviderStatusList = (state: ApplicationState): ProviderStatus[] => state.provider.providerStatusList

export const selectProviderRedirectURL = (state: ApplicationState): ProviderRedirectURL | null => state.provider.providerRedirectionURL

export const selectProviderStatus: any = (ProviderID: string) => {
  return createSelector(
    [selectProviderStatusList],
    (providerStatusList: ProviderStatus[]): ProviderStatus | undefined => {
      return providerStatusList.find((providerStatus) => providerStatus.ProviderID === ProviderID)
    }
  )
}

export const selectProviderWithStatusCheck = (
  providers: string[]
): any => {
  return createSelector(
    [selectProviderList, selectProviderStatusList],
    (
      providerList: ProviderAccount[],
      providerStatusList: ProviderStatus[]
    ): ProviderAccount | undefined => {
      if (providers !== undefined) {
        return providerList.find((provider) => {
          const isProviderExist = providers.includes(provider.ProviderID)
          const targetProviderStatus = providerStatusList.find(
            (providerStatus) =>
              providerStatus.ProviderID === provider.ProviderID
          )
          const isNotConnected =
            targetProviderStatus?.status !== ProviderStatusType.Connected &&
            targetProviderStatus?.status !==
              ProviderStatusType.RequireDisconnect
          return isProviderExist && isNotConnected
        })
      }
    }
  )
}
