import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { getPointsFromReferee } from '../../user/actions'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import earnImg from '../../../assets/images/campaign/referralPoint.png'
import { selectUser } from '../../user/selectors'
import { useTranslation } from 'react-i18next'

const PointsFromReferral: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user: UserState = useSelector(selectUser)
  const [isNewPointsFromReferral, setNewPointsFromReferral] = useState(false)
  useEffect(() => {
    dispatch(getPointsFromReferee()).then(() => {
      const earnedPoint = user.pointsFromReferee
      if (earnedPoint > 0) {
        setNewPointsFromReferral(true)
      }
    })
  }, [dispatch, user.pointsFromReferee])

  return (
    <>
      <BaseDrawer
        isOpen={isNewPointsFromReferral}
        onClose={() => setNewPointsFromReferral(false)}
      >
        <div className='referral-detail-drawer'>
          <div>
            <img
              src={earnImg}
              className='referral-detail-drawer__image'
              alt='Promo Code'
            />
          </div>
          <div>
            <p className='referral-detail-drawer__title'>Well Done!</p>
          </div>

          <div className='referral-detail-drawer__details'>
            <p>{t`ReferralNotificationDetail`}</p>
          </div>
          <Button
            type='button'
            className='referral-detail-drawer__button'
            onClick={() => setNewPointsFromReferral(false)}
          >
            {t`Ok`}
          </Button>
        </div>
      </BaseDrawer>
    </>
  )
}

export default PointsFromReferral
