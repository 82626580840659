import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CloudOffIcon from '@mui/icons-material/CloudOff'

import { useDispatch, useSelector } from 'react-redux'
import { selectIsOffline, selectIsShake } from '../../../config/selectors'
import { toggleOffline } from '../../../config/actions'

import './ConnectErrorBox.scss'

interface ConnectErrorBoxProps {
  handleRetry: () => void
}

const ConnectErrorBox: FC<ConnectErrorBoxProps> = ({ handleRetry }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shake: boolean = useSelector(selectIsShake)
  const offline: boolean = useSelector(selectIsOffline)

  useEffect(() => {
    window.addEventListener('online', () => {
      dispatch(toggleOffline(false))
      handleRetry()
    })

    window.addEventListener('offline', () => {
      dispatch(toggleOffline(true))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {offline &&
        <div className={`${shake ? 'shake' : ''}`}>
          <div className='error-connect-bar'>
            <div className='error-connect-bar__description'>
              <CloudOffIcon className='error-connect-bar__image' />

              <span>{t`ConnectError`}</span>
            </div>
            <div className='error-connect-bar__btn' onClick={handleRetry}>
              {t`Retry`}
            </div>
          </div>
        </div>}
    </>
  )
}

export default ConnectErrorBox
