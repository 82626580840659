import React, { FC, ReactNode } from 'react'

import Spinner from '../animation/Spinner/Spinner'

import './LoadingDialog.scss'

interface LoadingDialogProps {
  isSpinner: boolean
  className?: string
  height?: number
  width?: number
  children?: ReactNode
}

const LoadingDialog: FC<LoadingDialogProps> = ({
  isSpinner = true,
  children,
  className,
  height,
  width
}) => {
  const getClass = (): string => {
    return `${isSpinner ? 'loading-dialog__spinner' : ''}
      ${className ?? ''}`
  }

  return (
    <div className={`loading-dialog ${getClass()}`}>
      {isSpinner && <Spinner height={height} width={width} />}
      {!isSpinner &&
        <>
          {children}
        </>}
    </div>
  )
}

export default LoadingDialog
