import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import TimerIcon from '@mui/icons-material/Timer'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import { CampaignStatusList } from '../campaignStatus'
import { selectCampaignStatus } from '../selectors'
import { convertSecondsToDays } from '../../core/utils/time'
import CampaignIco from '../CampaignIco/CampaignIco'
import DynamicIcon from '../../ui/customIcons/DynamicIcon/DynamicIcon'
import { selectConfig } from '../../config/selectors'
import { Button } from '@mui/material'
import { useHistory } from 'react-router'
import coinIcon from '../../../assets/images/campaign/campaign-ico.png'
import StreakProgress from '../../ui/Streak/StreakProgress'
import { CampaignCtaActions, CampaignTypes } from '../CampaignConstants'
import moment from 'moment'
import MDEditor from '@uiw/react-md-editor'

import './CampaignDrawer.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import { isValidUrl, isExternal } from '../../utils'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import { RoutePath } from '../../core/routes/route-path'
import OpenProviderDialog from '../../provider/ProviderList/OpenProviderDialog/OpenProviderDialog'

interface CampaignDrawerProps {
  campaignItem: Campaign
  isCampaignDetailsOpen: boolean
  setIsCampaignDetailsOpen: (isCampaignDetailsOpen: boolean) => void
  handleOpenUnavailableMessage: () => void
}

const CampaignDrawer: FC<CampaignDrawerProps> = ({
  campaignItem,
  isCampaignDetailsOpen,
  setIsCampaignDetailsOpen,
  handleOpenUnavailableMessage
}) => {
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const campaignStatus: CampaignStatus | undefined = useSelector(
    selectCampaignStatus(campaignItem.CampaignID)
  )
  const config: ConfigState = useSelector(selectConfig)
  const history = useHistory()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (campaignStatus !== undefined && isCampaignDetailsOpen) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Detail View',
        payload: {
          status: campaignStatus.status,
          campaignID: campaignItem.CampaignID
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignStatus, isCampaignDetailsOpen])

  const handleIconClick = (): void => {
    handleOpenUnavailableMessage()
    if (campaignStatus?.status === CampaignStatusList.TimeLimited) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Timer Pressed',
        payload: {
          origin: 'campaign detail'
        }
      })

      handleOpenUnavailableMessage()
    } else if (campaignStatus?.status === CampaignStatusList.Locked) {
      trackEvent({
        page: AnalyticsCategory.Campaigns,
        action: 'Campaign Lock Pressed',
        payload: {
          origin: 'campaign detail'
        }
      })
    }
  }

  const isActionBtn = (): boolean =>
    (campaignStatus?.status === CampaignStatusList.Available &&
      Boolean(campaignItem.ActionButtonUrl)) ||
    campaignItem.CampaignType === CampaignTypes.CONNECT

  const getPointsField = (): string => {
    if (config.WordsForPoints.long != null) {
      return t([config.WordsForPoints.long])
    }
    return t`points`
  }

  const trackCTAClick = (campaignItem: Campaign): void => {
    trackEvent({
      action: 'Campaign Detail CTA',
      category: AnalyticsCategory.Campaigns,
      payload: {
        title: campaignItem.Title,
        description: campaignItem.Description,
        campaignID: campaignItem.CampaignID
      }
    })
  }

  const campaignButtonClick = (event: any = null): void => {
    if (event != null) {
      event.preventDefault()
    }
    setIsCampaignDetailsOpen(false)

    if (campaignItem.CampaignType === CampaignTypes.CONNECT) {
      if (campaignItem.ctaAction === CampaignCtaActions.OPEN) {
        setIsConnectDialogOpen(true)
      } else if (campaignItem.ctaAction === CampaignCtaActions.REDIRECT) {
        history.push(RoutePath.ConnectAccount)
      }
    } else {
      if (isValidUrl(campaignItem.ActionButtonUrl)) {
        const external = isExternal(campaignItem.ActionButtonUrl)
        if (external) {
          window.open(campaignItem.ActionButtonUrl)
        } else {
          history.push(campaignItem.ActionButtonUrl)
        }
      }

      trackCTAClick(campaignItem)
    }
  }

  return (
    <>
      <BaseDrawer
        isOpen={isCampaignDetailsOpen}
        onClose={() => setIsCampaignDetailsOpen(false)}
        customMargin={matchesMd ? '45px' : ''}
      >
        <div className='campaign-drawer'>
          <div className='campaign-drawer__status-ico'>
            {campaignStatus !== undefined && (
              <CampaignIco
                campaignStatus={campaignStatus}
                handleBadge={handleIconClick}
                campaignLocked={
                  campaignStatus.status === CampaignStatusList.Locked
                }
              />
            )}
          </div>

          <div>
            <span className='campaign-drawer__title'>{campaignItem.Title}</span>
          </div>

          <div className='campaign-drawer__details'>
            {+campaignItem.RewardPerUser > 0 && (
              <span className='campaign-drawer__timer'>
                <img
                  src={coinIcon}
                  className='icon__ico m-right-3'
                  alt='Coin Icon'
                />
                {campaignItem.RewardPerUser} {getPointsField()}
              </span>
            )}

            {convertSecondsToDays(campaignItem.earningsAllowedInTimeFrame) >
              0 &&
              (
                <span className='campaign-drawer__timer'>
                  <TimerIcon className='icon__ico m-right-3' />
                  {`x${convertSecondsToDays(
                  campaignItem.earningsAllowedInTimeFrame
                )}`}{' '}
                  {t`per`}{' '}
                  {convertSecondsToDays(campaignItem.timeFrameLengthInSeconds) ===
                1
                    ? `${t`DAY`}`
                    : `${convertSecondsToDays(
                      campaignItem.timeFrameLengthInSeconds
                    )} ${t`DAYS`}`}
                </span>
              )}

            {campaignItem?.badges.map((badge, i) => (
              <span className='campaign-drawer__timer' key={i}>
                <DynamicIcon
                  iconName={badge.BadgeIcon}
                  className='icon__ico m-right-3'
                />
                <span>{badge.BadgeLabel}</span>
              </span>
            ))}
          </div>

          {campaignItem?.CampaignType === CampaignTypes.STREAK && (
            <StreakProgress
              completion={campaignStatus?.streakCurrentLevelCompletion}
              currentLevel={campaignStatus?.streakCurrentLevel}
              totalLevel={campaignStatus?.streakTotalLevels}
              daysLeft={campaignStatus?.streakDaysLeft}
              completed={campaignStatus?.streakCompleted}
            />
          )}

          <div className='campaign-drawer__info'>
            <MDEditor.Markdown
              source={campaignItem.Description}
              style={{ whiteSpace: 'pre-wrap' }}
            />
          </div>

          {campaignItem?.CampaignType === CampaignTypes.STREAK && (
            <p className='campaign-drawer__available'>
              {t('StreakAvailableUntil', {
                time: moment
                  .utc(campaignStatus?.streakEndTimeUTC)
                  .format('MM/DD/YYYY')
              })}
            </p>
          )}
        </div>

        {isActionBtn() && (
          <div className='campaign-drawer__cta'>
            <Button
              onClick={campaignButtonClick}
              onTouchEnd={campaignButtonClick}
              className='btn'
            >
              {campaignItem.ActionButtonLabel}
            </Button>
          </div>
        )}
      </BaseDrawer>
      <OpenProviderDialog
        setIsConnectDialogOpen={setIsConnectDialogOpen}
        isConnectDialogOpen={isConnectDialogOpen}
        providers={campaignItem.providers}
      />
    </>
  )
}

export default CampaignDrawer
