import { createSelector } from 'reselect'
import { sortBy } from 'lodash'
import { selectProviderStatusList } from '../provider/selectors'

export const selectCampaignList = (state: ApplicationState): Campaign[] =>
  state.campaign.campaignList
export const selectCampaignListHidden = (state: ApplicationState): Campaign[] =>
  state.campaign.campaignListHidden
export const selectCampaignCategories = (
  state: ApplicationState
): CampaignCategory[] =>
  sortBy(state.campaign.campaignCategories, 'WebUiPosition')
export const selectCampaignPromotion = (
  state: ApplicationState
): CampaignPromotional => state.campaign.campaignPromotion

export const selectCampaignListByCategory = createSelector(
  [selectCampaignList, selectCampaignCategories],
  (
    campaignList: Campaign[],
    campaignCategories: CampaignCategory[]
  ): CampaignListByCategory[] => {
    return campaignCategories.map((category) => ({
      ...category,
      campaignList: campaignList.filter(
        (campaign) =>
          campaign.CampaignCategoryID === category.CampaignCategoryID
      )
    }))
  }
)

export const selectCampaignFeaturedList = (
  state: ApplicationState
): CampaignFeatured[] =>
  sortBy(state.campaign.campaignFeaturedList, 'uiPosition')

export const selectFeaturedCampaign = createSelector(
  [
    selectCampaignList,
    selectCampaignFeaturedList,
    selectCampaignListByCategory
  ],
  (
    campaignList: Campaign[],
    featuredCampaignList: CampaignFeatured[],
    CampaignListByCategory: CampaignListByCategory[]
  ): Campaign | undefined => {
    let firstFeaturedCampaign: Campaign | undefined

    featuredCampaignList.find(
      (featuredCampaign: CampaignFeatured) =>
        (firstFeaturedCampaign = campaignList.find(
          (campaign) =>
            campaign.CampaignID.toString() ===
            featuredCampaign.campaignId.toString()
        ))
    )

    if (firstFeaturedCampaign === undefined) {
      CampaignListByCategory.find(
        (campaignCategoryList) =>
          (firstFeaturedCampaign = campaignCategoryList.campaignList.find(
            (campaign) => campaign
          ))
      )
    }

    return firstFeaturedCampaign
  }
)

export const selectCampaignStatusesList = (
  state: ApplicationState
): CampaignStatus[] => state.campaign.campaignStatuses

export const selectCampaignStatus: any = (CampaignID: number) => {
  return createSelector(
    [selectCampaignStatusesList],
    (campaignStatuses: CampaignStatus[]): CampaignStatus | undefined => {
      return campaignStatuses.find(
        (campaignStatus: CampaignStatus) =>
          campaignStatus.CampaignID === CampaignID
      )
    }
  )
}

export const selectLockedCampaign: any = (providers: string[] | undefined) =>
  createSelector(
    [selectProviderStatusList],
    (providerStatusList: ProviderStatus[]): ProviderStatus[] | undefined => {
      if (providers !== undefined) {
        return providerStatusList.filter((item) =>
          providers.includes(item.ProviderID)
        )
      }

      return undefined
    }
  )

export const selectCampaignReferral = (
  state: ApplicationState
): CampaignReferral => state.campaign.campaignReferralInfo
