import React, { FC, MouseEvent } from 'react'

import { Icon, IconButton } from '@mui/material'

import { IdList } from '../../../core/utils/id-list'

import './BadgeIcon.scss'

interface BadgeIconProps {
  icon: FC
  clickBtn?: () => void
  shadow?: boolean
  className?: string
}

const BadgeIcon: FC<BadgeIconProps> = ({ icon, clickBtn, shadow = false, className = '' }) => {
  const handleClickBtn = (event: MouseEvent): void => {
    if (clickBtn !== undefined) {
      clickBtn()
    }
    event.stopPropagation()
  }

  return (
    <IconButton
      id={IdList.badgeIcon}
      color='inherit'
      aria-label='campaign status icon'
      className={`icon__circle badge ${shadow ? 'badge--shadow' : ''} ${className}`}
      onClick={handleClickBtn}
    >
      <Icon component={icon} className='icon__ico' />
    </IconButton>
  )
}

export default BadgeIcon
