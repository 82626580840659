import React, { FC } from 'react'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@react-hook/media-query'
import { useSelector } from 'react-redux'
import LockIcon from '@mui/icons-material/Lock'

import { selectUser } from '../../../user/selectors'
import { UserStatus } from '../../../user/userStatus'
import { IdList } from '../../../core/utils/id-list'
import { breakpoints } from '../../../core/utils/css-selectors'

import './OfferListPrompt.scss'

const OfferListPrompt: FC<any> = ({ setIsPhoneVerificationDrawerOpen }) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { t } = useTranslation()
  const user: UserState = useSelector(selectUser)

  const isLocked = (): boolean => {
    const status = user.userStatus
    return status !== undefined && (status === UserStatus.New || status === UserStatus.Consented)
  }

  return (
    <div className='flex align-center f-weight-bold'>
      {isLocked()
        ? (
          <>
            {!matchesMd
              ? (
                <div className='offer__list__prompt--ms' onClick={() => setIsPhoneVerificationDrawerOpen(true)}>
                  <div className='flex align-center offer__list__prompt--ms__description'>
                    <LockIcon
                      className='offer__list__prompt--ms__icon'
                      id={IdList.offerListPrompt}
                    />
                    <span className='offer__list__prompt--ms__text'>{t`offerPromptMessage`}</span>
                  </div>
                  <span className='offer__list__prompt--ms__btn'>{t`verify`}</span>
                </div>
              )
              : (
                <>
                  <IconButton
                    id={IdList.offerListPrompt}
                    color='inherit'
                    aria-label='lock'
                    className='icon__circle m-right-8'
                    onClick={() => setIsPhoneVerificationDrawerOpen(true)}
                  >
                    <LockIcon className='icon__ico' />
                  </IconButton>
                  <span className='offer__list__prompt__text--md'>{t`offerPromptMessageDesktop`}</span>
                </>

              )}
          </>)
        : (matchesMd && <span className='offer__list__prompt__text--md'>{t`offerPromptMessageDesktop`}</span>)}
    </div>
  )
}

export default OfferListPrompt
