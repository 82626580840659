import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './app/App'
import store from './app/core/redux/store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import './assets/style/main.scss'
import './locales/i18n'

const persistor = persistStore(store)

const container = document.getElementById('root')

if (container != null) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}
