import React, { FC, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import track, { useTracking } from 'react-tracking'
import { Link, useHistory } from 'react-router-dom'
import { Box, Button, FormHelperText, Typography } from '@mui/material'
import { RoutePath } from '../../core/routes/route-path'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './SignUp.scss'
import InviteCodeForm from './SignUpForms/InviteCodeForm'
import { useDispatch, useSelector } from 'react-redux'
import { setHomePath, signUp, validateInviteCode } from '../actions'
import { selectConfig } from '../../config/selectors'
import { toggleSignupWelcome } from '../../config/actions'
import { useMediaQuery } from '@react-hook/media-query'
import {
  AUTH_INDICATOR_SIZE,
  EXISTING_EMAIL,
  INVITE_CODE_ERROR,
  INVITE_CODE_WARNING,
  PASSWORD,
  RESET_PASSWORD_FIELDS,
  TEXT
} from '../constants'
import InviteCodeFormMobileView from './SignUpForms/InviteCodeFormMobileView'
import SignUpRightPanel from './SignUpRightPanel/SignUpRightPanel'
import InviteCodeValidationFailDialog from './InviteCodeValidationFailDialog'
import _ from 'lodash'
import {
  getUserInfo,
  setHasJustLoggedIn,
  updatePreferredLanguage
} from '../../user/actions'
import Spinner from '../../ui/animation/Spinner/Spinner'
import { parseCssTheme } from '../../core/utils/useTheme'
import { languageService } from '../../language/service'
import { useFormik } from 'formik'
import {
  showPasswordToggleIcon,
  validateEmail,
  validateName,
  validatePassword
} from '../utils'
import FormTextField from './FormTextField'
import InvalidInviteCodeDialog from './SignUpForms/InvalidInviteCodeDialog'

const SignUp: FC = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch<UserAction> = useDispatch()
  const [inviteCode, setInviteCode] = useState<string>('')
  const [isInviteCodeSubmit, setIsInviteCodeSubmit] = useState<boolean>(false)
  const config: ConfigState = useSelector(selectConfig)
  const history = useHistory()
  const { trackEvent } = useTracking()
  const [error, setError] = useState<string>('')
  const matchesMdw = useMediaQuery('(min-width: 1024px)')
  const matchesMdh = useMediaQuery('(min-height: 680px)')
  const isMobile: boolean = !matchesMdw && !matchesMdh
  const [isSignUpAgain, setIsSignUpAgain] = useState<boolean>(false)
  const [isSignUpWithWarning, setIsSignUpWithWarning] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isInvalidDialogOpen, setIsInvalidDialogOpen] = useState(false)
  const [isInviteCodeError, setIsInviteCodeError] = useState(false)

  const initialValues: SignUpForm = {
    name: '',
    email: '',
    password: '',
    confirmNewPassword: ''
  }
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false)
  const i18t = useTranslation()

  const customTheme = parseCssTheme(config.CssTheme)

  const handleInviteCodeSubmit = (inviteCode: string): void => {
    setInviteCode(inviteCode)
    if (!_.isEmpty(inviteCode)) {
      dispatch(
        validateInviteCode({ inviteCode, tenantID: config.TenantID ?? '' })
      )
        .then(() => {
          setError('')
          setIsInviteCodeSubmit(true)
        })
        .catch((err: any) => {
          setError(err.message)
          setIsInviteCodeError(true)
          setIsInvalidDialogOpen(true)
        })
    } else {
      setError('')
      setIsInviteCodeSubmit(true)
    }
  }

  const handleDiscard = (): void => {
    setIsInviteCodeSubmit(false)
    setInviteCode('')
    setError('')
  }

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData: SignUpForm) => {
      handleAdditionalInfoSubmit(formData)
    },
    validate: (values) => {
      let errors: { [key: string]: string } = {}
      const nameWithoutSpaces =
        values.name != null ? values.name.split(/ +/).join('') : ''
      errors = validateEmail(values.email, errors, i18t)
      errors = validatePassword(
        values.password,
        errors,
        RESET_PASSWORD_FIELDS.PASSWORD,
        i18t
      )
      errors = validatePassword(
        values.confirmNewPassword ?? '',
        errors,
        RESET_PASSWORD_FIELDS.CONFIRM_NEW_PASSWORD,
        i18t
      )
      if (
        errors.password == null &&
        errors.confirmNewPassword == null &&
        values.password !== values.confirmNewPassword
      ) {
        errors.confirmNewPassword = t`PasswordsNotMatch`
        errors.password = ''
      }
      errors = validateName(values.name, errors, nameWithoutSpaces, i18t)
      return errors
    },
    validateOnChange: false
  })
  const handleAdditionalInfoSubmit = (info: SignUpForm): void => {
    const userInfo = {
      inviteCode,
      tenantID: config.TenantID ?? '',
      ...info
    }
    trackEvent({
      page: 'SignUp',
      action: 'Complete SignUp'
    })
    setLoading(true)
    dispatch(signUp(userInfo))
      .then((res: any) => {
        if (
          config.SignUpScreenTitle != null &&
          config.SignUpWelcomeDescription != null &&
          config.SignUpWelcomeButtonLabel != null
        ) {
          dispatch(toggleSignupWelcome(true))
        }
        dispatch(setHasJustLoggedIn(true))
        history.push(RoutePath.Home)
        dispatch(getUserInfo())
        if (
          (!matchesMdh || !matchesMdw) &&
          config.SupportLanguage &&
          config.IsWelcomeScreenSelector
        ) {
          dispatch(updatePreferredLanguage(languageService.getLanguage()))
        }
      })
      .catch((err: { [key: string]: string }) => {
        const error = err.message.includes(EXISTING_EMAIL)
          ? t`ExistingEmailErr`
          : err.message
        if (error.includes(INVITE_CODE_ERROR)) {
          setIsSignUpAgain(true)
        } else if (error.includes(INVITE_CODE_WARNING)) {
          setIsSignUpWithWarning(true)
        } else {
          setError(error)
          setIsInviteCodeError(false)
          setIsInvalidDialogOpen(true)
        }
      })
      .finally(() => setLoading(false))
  }
  const handleInviteCodeValidationErrorClose = (): void => {
    setIsInviteCodeSubmit(false)
    setIsSignUpAgain(false)
  }

  const handleInviteCodeValidationWarningClose = (): void => {
    setIsSignUpWithWarning(false)
    dispatch(signUp(null)).then((res: any) => {
      if (
        config.SignUpScreenTitle != null &&
        config.SignUpWelcomeDescription != null &&
        config.SignUpWelcomeButtonLabel != null
      ) {
        dispatch(toggleSignupWelcome(true))
      }
      history.push(RoutePath.Home)
      dispatch(getUserInfo())
    })
  }

  const errorDisplay = (
    <FormHelperText>{t(error, { inviteCode })}</FormHelperText>
  )

  useEffect(() => {
    dispatch(setHomePath(false))
  }, [dispatch])
  const signUpForm = (
    <form
      onSubmit={form.handleSubmit}
      className={isMobile ? 'additional-fields-form' : ''}
    >
      <Box>
        <Typography className={`lato font-size ${isMobile ? 'center' : ''}`}>
          {t`SignUpFormAdditionalHeading`}
        </Typography>
        <Box className={`additional-fields ${isMobile ? 'mt-2' : ''}`}>
          <FormTextField
            id='name'
            name='name'
            value={form.values.name}
            label={t`Name`}
            errorField={form.errors.name}
            onChange={form.handleChange}
          />
          <FormTextField
            id='email'
            name='email'
            value={form.values.email}
            label={t`EmailSignIn`}
            errorField={form.errors.email}
            onChange={form.handleChange}
          />
          <FormTextField
            id='password'
            name='password'
            value={form.values.password}
            label={t`Password`}
            errorField={form.errors.password}
            onChange={form.handleChange}
            type={showPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showPassword,
                setShowPassword
              )
            }}
          />
          <FormTextField
            id='confirmNewPassword'
            name='confirmNewPassword'
            value={form.values.confirmNewPassword as string}
            label={t`ConfirmPassword`}
            errorField={form.errors.confirmNewPassword}
            onChange={form.handleChange}
            type={showConfirmNewPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showConfirmNewPassword,
                setShowConfirmNewPassword
              )
            }}
          />
        </Box>
      </Box>
      <Box className={isMobile ? 'mobile-actions' : ''}>
        {isMobile && (
          <Box onClick={handleDiscard} className='discard-box'>
            <Typography>{t`Discard`}</Typography>
          </Box>
        )}
        <Button
          id='sign-up-btn'
          type='submit'
          className={`btn btn-responsive lato ${
            form.values.name === '' ? 'disabled' : ''
          }`}
          disabled={form.values.name === ''}
          style={{
            backgroundColor: customTheme.button,
            color: customTheme.buttonText
          }}
        >
          {t`SignUp`}
        </Button>
        {!isMobile && (
          <Box onClick={handleDiscard} className='discard-box'>
            <Typography>{t`Discard`}</Typography>
          </Box>
        )}
      </Box>
    </form>
  )
  return isLoading ? (
    <Box className='spinner-container'>
      <Box className='spinner-holder'>
        <Spinner height={AUTH_INDICATOR_SIZE} width={AUTH_INDICATOR_SIZE} />
      </Box>
    </Box>
  ) : (
    <Box className='sign-up-container'>
      <Box
        className={`sign-up-left ${
          !matchesMdh || !matchesMdw ? 'overflow' : ''
        }`}
      >
        {matchesMdw && matchesMdh ? (
          <Box className='form-fields-box'>
            <Box className={`tenant-logo ${isInviteCodeSubmit ? 'mb-15' : ''}`}>
              <img src={config.TenantLogoURL ?? ''} alt='tenant-logo' />
            </Box>
            {!isInviteCodeSubmit && (
              <Link to={RoutePath.SignIn} className='back-to-sign-in no-decor' aria-label='back to sign in'>
                <ArrowBackIcon fontSize='small' className='arrow-responsive' aria-hidden='true' />
                <Typography>{t`GoBackToSignIn`}</Typography>
              </Link>
            )}
            <Typography className='sign-up-heading'>{t`SignUp!`}</Typography>
            {!isInviteCodeSubmit ? (
              <InviteCodeForm
                isInviteCodeDisabled={
                  !config.WelcomeScreenConfig.IsInviteCodeDisplay ?? false
                }
                isInviteCodeMandatory={
                  config.WelcomeScreenConfig.IsInviteCodeMandatory ?? false
                }
                customTheme={customTheme}
                handleInviteCodeSubmit={handleInviteCodeSubmit}
                moreInformationLink={
                  config.WelcomeScreenConfig.MoreInformationLink
                }
                moreInformationLinkDescription={
                  config.WelcomeScreenConfig.MoreInformationLinkDescription
                }
                termsAndConditionsUrl={
                  config.WelcomeScreenConfig.TermsAndConditionsURL ?? ''
                }
                privacyPolicyUrl={
                  config.WelcomeScreenConfig.PrivacyPolicyURL ?? ''
                }
                moreInformationUrl={
                  config.WelcomeScreenConfig.MoreInformationLinkURL ?? ''
                }
              />
            ) : (
              signUpForm
            )}
          </Box>
        ) : (
          <Box
            className={`h-100 mobile-view-box ${
              isInviteCodeSubmit ? 'w-100' : ''
            }`}
          >
            {!isInviteCodeSubmit ? (
              <InviteCodeFormMobileView
                isInviteCodeDisabled={
                  !config.WelcomeScreenConfig.IsInviteCodeDisplay ?? false
                }
                isInviteCodeMandatory={
                  config.WelcomeScreenConfig.IsInviteCodeMandatory ?? false
                }
                customTheme={customTheme}
                handleInviteCodeSubmit={handleInviteCodeSubmit}
                moreInformationLink={
                  config.WelcomeScreenConfig.MoreInformationLink
                }
                moreInformationLinkDescription={
                  config.WelcomeScreenConfig.MoreInformationLinkDescription
                }
                termsAndConditionsUrl={
                  config.WelcomeScreenConfig.TermsAndConditionsURL ?? ''
                }
                privacyPolicyUrl={
                  config.WelcomeScreenConfig.PrivacyPolicyURL ?? ''
                }
                moreInformationUrl={
                  config.WelcomeScreenConfig.MoreInformationLinkURL ?? ''
                }
              />
            ) : (
              <Box className='additional-form-box-mob'>
                <Link to={RoutePath.SignIn} className='back-to-sign-in' aria-label='back to sign in'>
                  <ArrowBackIcon
                    fontSize='medium'
                    className='arrow-responsive'
                    aria-hidden='true'
                  />
                </Link>
                <Typography className='sign-up-heading'>{t`SignUp!`}</Typography>
                {signUpForm}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <InvalidInviteCodeDialog
        isOpen={isInvalidDialogOpen}
        setIsOpen={setIsInvalidDialogOpen}
        errorMessage={errorDisplay}
        isInviteCode={isInviteCodeError}
      />
      {matchesMdw && matchesMdh && (
        <SignUpRightPanel customTheme={customTheme} />
      )}
      {isSignUpAgain && (
        <InviteCodeValidationFailDialog
          open={isSignUpAgain}
          handleClose={handleInviteCodeValidationErrorClose}
          content={t`CodeNotAvailableMsg`}
        />
      )}
      {isSignUpWithWarning && (
        <InviteCodeValidationFailDialog
          open={isSignUpWithWarning}
          handleClose={handleInviteCodeValidationWarningClose}
          content={t`SignUpWithWarningMsg`}
        />
      )}
      {isLoading && (
        <Box className='spinner-container'>
          <Box className='spinner-holder'>
            <Spinner height={AUTH_INDICATOR_SIZE} width={AUTH_INDICATOR_SIZE} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default track({
  page: AnalyticsCategory.SignUp
})(SignUp)
