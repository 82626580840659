import React, { ReactElement, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import { useTranslation } from 'react-i18next'
import './PasswordResetEmailSentDialog.scss'
import { useTracking } from 'react-tracking'

interface PasswordResetEmailSentDialogProps {
  open: boolean
  handleClose: () => void
  handleClick: () => void
}

const PasswordResetEmailSentDialog = ({
  open,
  handleClose,
  handleClick
}: PasswordResetEmailSentDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent({
      page: 'Reset Password',
      action: 'Send Reset Email'
    })
  }, [trackEvent])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='password-reset-email-dialog'
      maxWidth='xs'
    >
      <CheckCircleOutlinedIcon className='check-icon' fontSize='large' />
      <DialogTitle className='title'>
        {t`EmailSent`}
        <CloseOutlinedIcon onClick={handleClose} aria-label='close' className='close' />
      </DialogTitle>
      <DialogContent className='content'>
        {t`ResetPasswordEmailSentText`}
      </DialogContent>
      <DialogActions>
        <Button id='password-reset-email-ok' className='btn dialog-btn' onClick={handleClick}>
          {t`Ok`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PasswordResetEmailSentDialog
