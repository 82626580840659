import * as Sentry from '@sentry/react'
import { TFunction } from 'react-i18next'

export const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url, window.location.href))
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        method: 'isValidUrl'
      }
    })
    return false
  }
}

export const isExternal = (url: string): boolean => {
  const mainUrl = new URL(window.location.href)
  const compareUrl = new URL(url, window.location.href)
  return mainUrl.hostname !== compareUrl.hostname
}

export const getErrorMessage = (
  error: any,
  t: TFunction<'translation', undefined>
): string => {
  return (
    error?.data?.error_description ??
    error?.response?.data?.error_description ??
    error?.message ??
    t`SomethingWrong`
  )
}
