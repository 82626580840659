import React, { FC, useEffect, useState } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import Slider from 'react-slick'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import StarOutlineIcon from '@mui/icons-material/StarOutline'

import OfferListItem from '../OfferListItem/OfferListItem'
import CustomArrow from '../../../ui/btn/CustomArrow/CustomArrow'
import { breakpoints } from '../../../core/utils/css-selectors'

import './OfferListCategory.scss'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../user/selectors'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { selectCurrentTutorialState } from '../../../tutorial/selectors'

interface OfferListCategoryProps {
  offerCategory: OfferListByCategory
  isFeatured?: boolean
  searchFilter: string
  allowFilter: boolean
  handleClickLockedOffer: () => void
  isSingleValidOfferCategory?: boolean
  hasOnlyOneCategory?: false
  offerCategoryCount?: number
}

const SLIDES_NUM_DESKTOP = 3
const SLIDES_NUM_MOBILE = 1.5

const OfferListCategory: FC<OfferListCategoryProps> = ({
  offerCategory,
  isFeatured,
  handleClickLockedOffer,
  allowFilter,
  searchFilter,
  isSingleValidOfferCategory = false,
  offerCategoryCount
}) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { featureFlags }: UserState = useSelector(selectUser)
  const [showOffers, setShowOffers] = useState<boolean>(
    offerCategoryCount === 1
  )
  const currentTutorialState = useSelector(selectCurrentTutorialState)

  useEffect(() => {
    if (featureFlags.UX_UPDATES && isSingleValidOfferCategory) {
      setShowOffers(true)
    }
  }, [featureFlags, isSingleValidOfferCategory])
  const showValue = (allowFilter: boolean): any => {
    if (allowFilter) {
      if (!featureFlags.UX_UPDATES) {
        return (
          <Slider
            className='slider variable-width'
            dots={false}
            arrows={matchesMd}
            draggable={!matchesMd}
            infinite={false}
            slidesToShow={matchesMd ? SLIDES_NUM_DESKTOP : SLIDES_NUM_MOBILE}
            slidesToScroll={1}
            nextArrow={
              <CustomArrow side='next' icon={KeyboardArrowRightIcon} />
            }
            prevArrow={<CustomArrow side='prev' icon={KeyboardArrowLeftIcon} />}
          >
            {offerCategory.offerList.map(
              (offerItem, index) =>
                offerItem.Title.toUpperCase().includes(
                  searchFilter.toUpperCase()
                ) && (
                  <OfferListItem
                    key={index}
                    offerItem={offerItem}
                    handleClickLockedOffer={handleClickLockedOffer}
                  />
                )
            )}
          </Slider>
        )
      } else {
        return (
          showOffers && (
            <div className='offers-with-category-updated'>
              {offerCategory.offerList.map(
                (offerItem, index) =>
                  offerItem.Title.toUpperCase().includes(
                    searchFilter.toUpperCase()
                  ) && (
                    <OfferListItem
                      key={index}
                      offerItem={offerItem}
                      handleClickLockedOffer={handleClickLockedOffer}
                      showUpdatedUI={!matchesMd && featureFlags.UX_UPDATES}
                      isFavoriteOfferAvailable={featureFlags.FAVORITE_OFFER}
                    />
                  )
              )}
            </div>
          )
        )
      }
    } else {
      if (!featureFlags.UX_UPDATES) {
        return (
          <Slider
            className='slider variable-width'
            dots={false}
            arrows={matchesMd}
            draggable={!matchesMd}
            infinite={false}
            slidesToShow={matchesMd ? SLIDES_NUM_DESKTOP : SLIDES_NUM_MOBILE}
            slidesToScroll={1}
            nextArrow={
              <CustomArrow side='next' icon={KeyboardArrowRightIcon} />
            }
            prevArrow={<CustomArrow side='prev' icon={KeyboardArrowLeftIcon} />}
          >
            {offerCategory.offerList.map((offerItem, index) => (
              <OfferListItem
                key={index}
                offerItem={offerItem}
                handleClickLockedOffer={handleClickLockedOffer}
              />
            ))}
          </Slider>
        )
      } else {
        return (
          (showOffers || currentTutorialState.tutorialOn) && (
            <div className='offers-with-category-updated'>
              {offerCategory.offerList.map((offerItem, index) => (
                <OfferListItem
                  key={index}
                  offerItem={offerItem}
                  handleClickLockedOffer={handleClickLockedOffer}
                  showUpdatedUI={featureFlags.UX_UPDATES}
                  isFavoriteOfferAvailable={featureFlags.FAVORITE_OFFER}
                />
              ))}
            </div>
          )
        )
      }
    }
  }
  return (
    <div className='offer-list-category'>
      <div
        className={`offer-list-category__title ${
          featureFlags.UX_UPDATES ? 'mb-1' : ''
        }`}
      >
        {isFeatured === true ? (
          <StarOutlineIcon
            className={`m-right-8 ${
              !featureFlags.UX_UPDATES
                ? 'offer-list-category__ico'
                : 'updated-UI-icon'
            }`}
          />
        ) : (
          !_.isEmpty(offerCategory.iconImageUrl) && (
            <img
              src={offerCategory.iconImageUrl}
              alt='Offer List Category Icon'
              className={`m-right-8 ${
                !featureFlags.UX_UPDATES
                  ? 'offer-list-category__ico'
                  : 'updated-UI-icon'
              }`}
            />
          )
        )}
        <span
          className={`${
            featureFlags.UX_UPDATES ? 'width-93' : ''
          } capitalize-text`}
        >
          {offerCategory.CategoryName}
        </span>
        {featureFlags.UX_UPDATES && (
          <button
            className='overlay-button'
            onClick={() => setShowOffers(!showOffers)}
            aria-label='expand offer category list'
          >
            <span>{showOffers ? <ExpandLess /> : <ExpandMore />}</span>
          </button>
        )}
      </div>
      <div>{showValue(allowFilter)}</div>
    </div>
  )
}

export default OfferListCategory
