/* eslint-disable react/jsx-indent */
import React, { FC, useState, useEffect } from 'react'
import { Skeleton, ButtonBase } from '@mui/material'
import { useSelector } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import { selectIsActionLoaded } from '../../../config/selectors'
import { GET_CONFIG_INFO } from '../../../config/actionTypes'
import { useTranslation } from 'react-i18next'

import referCodeImg from '../../../../assets/images/campaign/referralHeaderImg.png'
import referCodeImgDesktop from '../../../../assets/images/campaign/referralHeaderImgDesktop.png'
import ReferHowTo from '../ReferHowTo/ReferHowTo'
import { useTracking } from 'react-tracking'
import './ReferHeader.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'

interface ReferHeaderProps {
  descriptionMarkdown?: string
  referralConfiguration: any
  referralInstructions: any
}

const ReferHeader: FC<ReferHeaderProps> = ({
  descriptionMarkdown = null,
  referralConfiguration = null,
  referralInstructions = null
}) => {
  const { t } = useTranslation()
  const [isOpenReferHowTo, setIsOpenReferHowTo] = useState<boolean>(false)
  const isConfigLoaded = useSelector<boolean>(
    selectIsActionLoaded(GET_CONFIG_INFO)
  )
  const [referralTitle, setTitle] = useState<string>(t`ReferTitle`)
  const [referralDescription, setDescription] = useState<string>(
    t`ReferDescription`
  )
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const [referralImage, setImg] = useState(
    matchesMd ? referCodeImgDesktop : referCodeImg
  )
  const [referralHowTo, setHowTo] = useState<string>(t`ReferHowTo`)
  const { trackEvent } = useTracking()

  const openHowTo = (): void => {
    trackEvent({
      action: 'Referral Instruction',
      page: 'Camapign',
      payload: {
        campaignID: referralConfiguration?.CampaignID,
        origin: 'How does it work?'
      }
    })
    setIsOpenReferHowTo(true)
  }
  useEffect(() => {
    if (worthToChange(referralConfiguration?.ReferralTitle)) {
      setTitle(referralConfiguration.ReferralTitle)
    }
    if (worthToChange(referralConfiguration?.ReferralDescription)) {
      setDescription(referralConfiguration.ReferralDescription)
    }
    if (worthToChange(referralConfiguration?.ReferralImageURL)) {
      setImg(referralConfiguration.ReferralImageURL)
    }
    if (worthToChange(referralConfiguration?.HowItWork)) {
      setHowTo(referralConfiguration.HowItWork)
    }
  }, [referralConfiguration])

  const worthToChange = (obj: any): boolean => {
    if (obj != null && obj !== '') {
      return obj
    }
    return false
  }
  function addImage (): any {
    return (
      <img src={referralImage} className='refer-header__img' alt='Refer Code' />
    )
  }
  const titleContent =
    isConfigLoaded === true ? (
      <div className='refer-header__title'>{referralTitle}</div>
    ) : (
      <Skeleton variant='rectangular' classes={{ root: 'refer-header__title' }} />
    )

  return (
    <div className='refer-header'>
      {matchesMd && titleContent}
      {addImage()}
      {!matchesMd && titleContent}
      <div className='refer-header__instruction'>
        {referralDescription}
        {descriptionMarkdown !== null &&
          descriptionMarkdown !== undefined &&
          descriptionMarkdown !== '' && (
          <ReactMarkdown linkTarget='_blank'>
            {descriptionMarkdown}
          </ReactMarkdown>
        )}
      </div>

      {!matchesMd && (
        <div className='refer-header__howto default-link'>
          <ButtonBase onClick={openHowTo}>{referralHowTo}</ButtonBase>
          <ReferHowTo
            referralInstructions={referralInstructions}
            isOpenReferHowTo={isOpenReferHowTo}
            setIsOpenReferHowTo={setIsOpenReferHowTo}
          />
        </div>
      )}
    </div>
  )
}

export default ReferHeader
