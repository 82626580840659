import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { useSelector } from 'react-redux'
import { selectConfig } from '../../../config/selectors'
import _ from 'lodash'
import './InvalidInviteCodeDialog.scss'

interface InvalidInviteCodeDialogProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  errorMessage: ReactElement | string
  isInviteCode: boolean
}

const InvalidInviteCodeDialog = ({
  isOpen,
  setIsOpen,
  errorMessage,
  isInviteCode
}: InvalidInviteCodeDialogProps): ReactElement => {
  const { t } = useTranslation()
  const config = useSelector(selectConfig)

  const hasSupportInfo =
    !_.isEmpty(config.SupportPhoneNumber) ||
    !_.isEmpty(config.CustomerSupportLink)

  const supportInfo = !_.isEmpty(config.SupportPhoneNumber)
    ? config.SupportPhoneNumber
    : !_.isEmpty(config.CustomerSupportLink)
      ? config.CustomerSupportLink
      : ''

  const handleClose = (): void => {
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className='invalid-code-dialog'
      maxWidth='xs'
    >
      <ErrorOutlineOutlinedIcon
        className='info-icon'
        color='error'
        fontSize='large'
      />
      <DialogTitle className='title'>
        <button onClick={handleClose} aria-label='Close' className='close'>
          <CloseOutlinedIcon />
        </button>
        {isInviteCode ? t`CodeNotAvailable` : t`EmailNotValid`}
      </DialogTitle>
      <DialogContent className='content'>{errorMessage}</DialogContent>
      <DialogActions>
        <Button
          id='invalid-credentials'
          className='btn dialog-btn'
          onClick={handleClose}
        >
          {t`Ok`}
        </Button>
      </DialogActions>
      {hasSupportInfo && (
        <DialogContent className='support-content'>
          {t`CustomerSupportInfo`}
          <span className='highlight'>{supportInfo}</span>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default InvalidInviteCodeDialog
