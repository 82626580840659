import { FC, useState } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useMediaQuery } from '@react-hook/media-query'
import { Button } from '@mui/material'
import { useTracking } from 'react-tracking'

import { RedemptionCenterCondition } from '../../../redemptionCenterCondition'
import BaseDrawer from '../../../../ui/BaseDrawer/BaseDrawer'
import { userService } from '../../../../user/service'
import { breakpoints } from '../../../../core/utils/css-selectors'
import { isValidPhoneNumber } from '../../../../core/utils/validation'
import { IdList } from '../../../../core/utils/id-list'
import CustomPhoneInput from './CustomPhoneInput/CustomPhoneInput'
import * as phoneVerificationConstants from '../PhoneVerificationConstants'
import './PhoneVerificationMobile.scss'
import { AnalyticsCategory } from '../../../../core/analytics/analyticsCategory'

interface UserVerifyPhoneNumber {
  phoneNumber: string
}

interface PhoneVerificationMobileProps {
  isPhoneVerificationMobileOpen: boolean
  handleClosePhoneVerificationMobile: () => void
  phoneVerifyImg: string
  handleOpenPhoneVerificationWithCode: (
    verificationCode: VerificationCode,
    mobilePhone: string
  ) => void
  handleOpenPhoneVerificationAlreadyUsed: (mobilePhone: string) => void
  isMigrationRequest: boolean
}

const PHONE_ALREADY_REGISTERED = 'PHONE_ALREADY_REGISTERED'
const PhoneVerificationMobile: FC<PhoneVerificationMobileProps> = ({
  isPhoneVerificationMobileOpen,
  handleClosePhoneVerificationMobile,
  phoneVerifyImg,
  handleOpenPhoneVerificationWithCode,
  handleOpenPhoneVerificationAlreadyUsed,
  isMigrationRequest
}) => {
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { trackEvent } = useTracking()
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const classForm = 'phone-verification-mobile__form-control__field'
  const initialValues: UserVerifyPhoneNumber = { phoneNumber: '' }

  const handleValidPhoneNumber = (value: string): void => {
    setPhoneNumber(value)
    setErrorMessage('')
  }

  const handleChange = (values: NumberFormatValues): void => {
    isValidPhoneNumber(values.value)
      ? handleValidPhoneNumber(values.value)
      : setErrorMessage(t`invalidPhoneNumber`)
  }

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      let successStatus: boolean

      userService
        .validatePhoneNumber(phoneNumber, isMigrationRequest)
        .then((codeInfo: VerificationCode) => {
          handleClosePhoneVerificationMobile()
          handleOpenPhoneVerificationWithCode(codeInfo, phoneNumber)
          setErrorMessage('')
          successStatus = true
        })
        .catch((error) => {
          successStatus = false
          let errorMsgSet = false
          if (error != null) {
            if (error.data != null) {
              if (isPhoneRegistered(error.data.error_description)) {
                handleOpenPhoneVerificationAlreadyUsed(phoneNumber)
                handleClosePhoneVerificationMobile()
              } else if (error.data.error_description != null) {
                let errorMsg = error.data.error_description
                if (
                  errorMsg ===
                  phoneVerificationConstants.PHONE_VERIFICATION_ERROR_MSG
                    .MSG_SENT_FAIL
                ) {
                  errorMsg = `${t`MSG_SENT_FAIL`}`
                }
                setErrorMessage(errorMsg)
                errorMsgSet = true
              }
            }
          } else if (
            error == null ||
            (!errorMsgSet && !isPhoneRegistered(error.data.error_description))
          ) {
            setErrorMessage('Error happened. Please try again')
          }
        })
        .finally(() => {
          trackEvent({
            page: AnalyticsCategory.PhoneVerification,
            action: 'Send Verification Code',
            payload: {
              status: successStatus ? 'success' : 'failed'
            }
          })
        })
    }
  })

  const isPhoneRegistered = (message: string): boolean => {
    return message === PHONE_ALREADY_REGISTERED
  }

  const submitTheForm = async (event: any): Promise<any> => {
    event.preventDefault()
    await form.submitForm()
    setPhoneNumber('')
  }

  const handleClose = (): void => {
    setPhoneNumber('')
    handleClosePhoneVerificationMobile()
  }

  return (
    <BaseDrawer
      isOpen={isPhoneVerificationMobileOpen}
      onClose={handleClose}
      centerCondition={RedemptionCenterCondition.mobileOnly}
      title={t`VerifyAccount`}
      customMargin={matchesMd ? '45px' : ''}
    >
      <div className='phone-verification-mobile'>
        <div className='phone-verification-mobile__content'>
          <img
            src={phoneVerifyImg}
            alt='Phone Verifying'
            className='phone-verification-mobile__content__img'
          />
          {matchesMd && (
            <div className='phone-verification-mobile__content__title'>
              {t`VerifyAccount`}
            </div>
          )}
          <div className='phone-verification-mobile__content__description'>
            <div className='phone-verification-mobile__content__description__title'>{t`VerifyPhoneTitle`}</div>
            <div className='phone-verification-mobile__content__description__subtitle'>
              {t`verifyPhoneNumberDescription`}
            </div>
          </div>
        </div>
        <form className='phone-verification-mobile__form-control'>
          <label className='phone-verification-mobile__form-control__label'>{t`MobilePhoneNumber`}</label>
          <NumberFormat
            format='(###) ###-####'
            mask='_'
            placeholder='(000) 000-0000'
            name='phoneNumber'
            type='tel'
            onChange={form.handleChange}
            onValueChange={handleChange}
            customInput={CustomPhoneInput}
            customInputProps={{
              errorMessage,
              classForm
            }}
          />

          <Button
            id={IdList.submitPhone}
            type='submit'
            className='phone-verification-mobile__form-control__btn-active btn'
            classes={{ disabled: 'btn-disable' }}
            disabled={phoneNumber.length < 10}
            onClick={submitTheForm}
            onTouchEnd={submitTheForm}
          >
            {t`Continue`}
          </Button>
        </form>
      </div>
    </BaseDrawer>
  )
}

export default PhoneVerificationMobile
