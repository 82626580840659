import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined'
import './StreakProgress.scss'

interface StreakProgressProps {
  completion: number
  currentLevel: number
  totalLevel: number
  daysLeft: number
  completed: boolean
}

const StreakProgress: FC<StreakProgressProps> = ({
  completion,
  totalLevel,
  currentLevel,
  daysLeft,
  completed
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='StreakProgress-upper'>
        <div className='StreakProgress-left-info'>
          <p className='StreakProgress-left-info-complete'>
            {t('StreakCompleted', {
              completion
            })}
          </p>
          <p className='StreakProgress-left-info-level'>
            {t('StreakLevelDisplay', {
              currentLevel,
              totalLevel
            })}
          </p>
        </div>
        <div className='StreakProgress-right-info'>
          <p className='StreakProgress-right-info-dayleft'>
            {completed
              ? t`StreakFinished`
              : daysLeft > 0 ? t('StreakDaysLeft', {
                daysLeft
              }) : t`LastDay`}
          </p>
          <AccessTimeOutlined className='icon__ico StreakProgress-right-info-timeicon' />
        </div>
      </div>
      <div className='StreakProgress-outer'>
        <div
          className='StreakProgress-inner'
          style={{
            width: `${completion}%`
          }}
        />
      </div>
    </>
  )
}

export default StreakProgress
