import React, { FC, ReactNode, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import store from '../redux/store'
import initInterceptor from './interceptorFunc'

interface InterceptorProps {
  children: ReactNode
}

const Interceptor: FC<InterceptorProps> = ({ children }) => {
  const history = useHistory()

  useEffect(() => {
    initInterceptor(store, history)
  }, [history])

  return <>{children}</>
}

export default Interceptor
