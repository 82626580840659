import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectProviderStatus } from '../../selectors'
import ProviderDialog from '../ProviderDialog/ProviderDialog'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import { providerLabelTemplate } from '../../../core/utils/providerLabelTemplate'
import ResultDialog from '../ResultDialog/ResultDialog'
import { selectProviderWithStatusCheck } from '../../../provider/selectors'

interface OpenProviderDialogProps {
  isConnectDialogOpen: boolean
  setIsConnectDialogOpen: (isConnectDialogOpen: boolean) => void
  providers: string[]
}

const OpenProviderDialog: FC<OpenProviderDialogProps> = ({
  isConnectDialogOpen,
  setIsConnectDialogOpen,
  providers
}) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const [isSpinner, setIsSpinner] = useState<boolean>(true)
  const targetProvider: ProviderAccount | undefined = useSelector(
    selectProviderWithStatusCheck(providers)
  )
  const targetProviderStatus: ProviderStatus | undefined = useSelector(
    selectProviderStatus(targetProvider?.ProviderID)
  )
  const [isResultOpen, setIsResultOpen] = useState<boolean>(false)

  const showSpinner = (): void => {
    setIsConnectDialogOpen(false)
    setIsSpinner(true)
    setIsResultOpen(true)
  }

  const showMessage = (
    targetProviderStatus?: ProviderStatus
  ): void => {
    if (targetProviderStatus != null) {
      setIsSpinner(false)
    } else {
      setIsResultOpen(false)
      setIsSpinner(true)
      setIsConnectDialogOpen(true)
    }
  }

  const handleResultClose = (flag: boolean): void => {
    setIsSpinner(true)
    setIsResultOpen(flag)
  }

  return (
    <>
      {targetProvider !== undefined && (
        <>
          <ProviderDialog
            isConnectDialogOpen={isConnectDialogOpen}
            setIsConnectDialogOpen={setIsConnectDialogOpen}
            provider={targetProvider}
            handleSubmitStart={showSpinner}
            handleSubmit={showMessage}
            identifier={
              targetProviderStatus !== undefined
                ? targetProviderStatus.identifier
                : ''
            }
            isFromProviderItem={matchesMd}
          />

          <ResultDialog
            isOpen={isResultOpen}
            setIsOpen={handleResultClose}
            isSpinner={isSpinner}
            providerStatus={targetProviderStatus}
            label={
              targetProvider.translations?.fullName != null
                ? providerLabelTemplate(
                  targetProvider.Label,
                  targetProvider.translations.fullName
                )
                : ''
            }
          />
        </>
      )}
    </>
  )
}

export default OpenProviderDialog
