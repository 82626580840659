import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TransactionHistoryList from '../TransactionHistoryList/TransactionHistoryList'
import { useTracking } from 'react-tracking'
import HistoryIcon from '@mui/icons-material/History'

import { selectUser } from '../selectors'
import { IconButton } from '@mui/material'
import { IdList } from '../../core/utils/id-list'
import { selectConfig } from '../../config/selectors'
import CountUp from 'react-countup'

import './BalanceMobile.scss'
import {
  BALANCE_ANIMATION_COUNT_START,
  BALANCE_ANIMATION_LIMIT_CHECK,
  BALANCE_ANIMATION_MAX_DURATION,
  BALANCE_ANIMATION_MIN_DURATION
} from '../userConstants'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'

const BalanceMobile: FC = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const user: UserInfo = useSelector(selectUser)
  const { isRefreshCounter }: UserState = useSelector(selectUser)
  const [point, setPoints] = useState<number>(0)
  const config: ConfigState = useSelector(selectConfig)
  const [resetCount, setResetCount] = useState(0)

  const handleOpenTransactionHistory = (event: MouseEvent): void => {
    if (user.earningsCount > 0 || user.redeemsCount > 0) {
      event.stopPropagation()
      trackEvent({
        page: AnalyticsCategory.PTH,
        action: 'PTH View',
        payload: {
          origin: 'point balance'
        }
      })
      setIsOpenTransactionHistory(true)
    }
  }

  const [isOpenTransactionHistory, setIsOpenTransactionHistory] =
    useState<boolean>(false)

  useEffect(() => {
    let points = 0
    if (user.Balance !== null && user.Balance !== undefined && user.Balance !== '') {
      points = parseInt(user.Balance)
    }
    setPoints(points)
  }, [user.Balance])

  useEffect(() => {
    setResetCount((prevResetCount) => prevResetCount + 1)
  }, [isRefreshCounter])

  const getPtsField = (): string => {
    if (config.WordsForPoints.short != null) {
      return t([config.WordsForPoints.short])
    }
    return t`pts`
  }

  return (
    <div className='balance-mobile'>
      <div className='balance-mobile-box'>
        <div className='balance-text-box'>
          <CountUp
            key={resetCount}
            start={BALANCE_ANIMATION_COUNT_START}
            end={point}
            duration={
              point < BALANCE_ANIMATION_LIMIT_CHECK
                ? BALANCE_ANIMATION_MIN_DURATION
                : BALANCE_ANIMATION_MAX_DURATION
            }
          />
          &nbsp;
          <span className='text-helper'>{getPtsField()}</span>
        </div>
        <div
          className='balance-mobile-info'
          onClick={(event) => handleOpenTransactionHistory(event)}
        >
          {(user.earningsCount > 0 || user.redeemsCount > 0) && (
            <>
              <IconButton
                onClick={handleOpenTransactionHistory}
                id={IdList.balanceHistory}
                color='inherit'
                aria-label='history'
                className='balance-mobile-icon'
              >
                <HistoryIcon />
              </IconButton>
              <TransactionHistoryList
                isOpenTransactionHistory={isOpenTransactionHistory}
                setIsOpenTransactionHistory={setIsOpenTransactionHistory}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BalanceMobile
