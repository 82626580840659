import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { useTracking } from 'react-tracking'
import { Button, Link } from '@mui/material'
import LinkOffIcon from '@mui/icons-material/LinkOff'

import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import { RedemptionCenterCondition } from '../../../offer/redemptionCenterCondition'
import { IdList } from '../../../core/utils/id-list'
import { disconnectProviderStatus, getProviderList } from '../../actions'

import './DisconnectDialog.scss'

interface DisconnectDialogProps {
  isOpen: boolean
  setIsDisconnectOpen: (flag: boolean) => void
  providerStatus: ProviderStatus | undefined
  label: string
  handleSubmitStart: () => void
  handleSubmitFinish: () => void
}

const DisconnectDialog: FC<DisconnectDialogProps> = ({ isOpen, setIsDisconnectOpen, providerStatus, label = '', handleSubmitStart, handleSubmitFinish }) => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dispatch = useDispatch()

  const handleDisconnect = (event: any = null): void => {
    if (event != null) {
      event.preventDefault()
    }
    if (providerStatus !== undefined) {
      handleSubmitStart()
      setIsDisconnectOpen(false)
      return dispatch(disconnectProviderStatus(providerStatus.ProviderID))
        .then(() => {
          trackEvent({
            action: 'Connect - App Disconnected',
            page: 'Connect Apps',
            payload: {
              appID: providerStatus.ProviderID
            }
          })
          handleSubmitFinish()
          dispatch(getProviderList())
        })
        .catch((error: AxiosResponse) => Sentry.captureException(error, {
          tags: {
            method: 'handleDisconnect'
          }
        }))
    }
  }

  return (
    <>
      <BaseDrawer
        isOpen={isOpen}
        isCentered
        centerCondition={RedemptionCenterCondition.both}
        onClose={() => setIsDisconnectOpen(false)}
      >
        <div className='provider-disconnect-dialog'>
          <LinkOffIcon className='provider-disconnect-dialog__ico' />

          <div className='provider-disconnect-dialog__msg'>
            {`${t`ConfirmDisconnectProvider`} ${label}?`}
            &nbsp;&nbsp;
            {t`ProviderDisconnectMsg`}
          </div>

          <Button
            id={IdList.providerSuccessDialog}
            onClick={handleDisconnect}
            onTouchEnd={handleDisconnect}
            className='btn provider-disconnect-dialog__btn'
          >
            {t`Disconnect`}
          </Button>

          <Link
            component='button'
            className='provider-disconnect-dialog__link default-link'
            onClick={() => setIsDisconnectOpen(false)}
          >
            {t`Cancel`}
          </Link>
        </div>
      </BaseDrawer>
    </>
  )
}

export default DisconnectDialog
