import axios from 'axios'
import { configService } from '../../config/service'
import { TENANT_ID_HEADER } from './constants'
import _ from 'lodash'

let baseURL: string | undefined = process.env.REACT_APP_API_URL
const tenantID = process.env.REACT_APP_TENANT_ID_FOR_CLIENT

if (baseURL === undefined) {
  baseURL = ''
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    ...(!_.isEmpty(tenantID) ? { [TENANT_ID_HEADER]: tenantID } : {})
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300
  }
})

export async function getInitialTenantInfo (TenantID?: string): Promise<ConfigInfo> {
  return TenantID != null
    ? await configService.getConfigInfoByTenant(TenantID)
    : await configService.getConfigInfo()
}

export { axiosInstance }
