import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import { languageService } from '../service'
import i18n from 'i18next'
import {
  getCampaignCategories,
  getCampaignList,
  getReferralInfo
} from '../../campaign/actions'
import { getOfferCategories, getOfferList } from '../../offer/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getEarningList, updatePreferredLanguage } from '../../user/actions'
import { getConfigInfoByTenant } from '../../config/actions'
import { getProviderList } from '../../provider/actions'
import { selectLanguageList } from '../../config/selectors'
import { useTracking } from 'react-tracking'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import _ from 'lodash'
import CheckIcon from '@mui/icons-material/Check'
import { getUpdateCardList } from '../../updateCards/actions'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
const { REACT_APP_TENANT_ID_FOR_CLIENT } = process.env

const MultiLanguageDropDown: FC = () => {
  const { trackEvent } = useTracking()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const initialLanguage: string = languageService.getLanguage()
  const initialLanguageTitle: string = languageService.getLanguageTitle()
  const selectedLanguage: string = initialLanguage ?? ''
  const [selectedTitle, setSelectedTitle] = useState(initialLanguageTitle ?? '')
  const [isChangeLang, setIsChangeLang] = useState<boolean>(false)
  const languageList: LanguageList[] = useSelector(selectLanguageList)
  const [isOpenLangMenu, setIsOpenLangMenu] = useState<boolean>(false)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (initialLanguage !== selectedLanguage) {
      setIsChangeLang(true)
    }
  }, [initialLanguage, selectedLanguage])

  const handleClickMenu = (): void => {
    setIsOpenLangMenu(!isOpenLangMenu)
  }

  const handleSave = async (languageCode: string): Promise<void> => {
    setIsOpenLangMenu(false)
    const targetLanguage = _.find(languageList, {
      LanguageCode: languageCode
    })
    if (targetLanguage != null) {
      languageService.setLanguage(languageCode, targetLanguage.LanguageName)
      setSelectedTitle(targetLanguage.LanguageName)
      await i18n.changeLanguage(languageCode)
      await Promise.all([
        dispatch(updatePreferredLanguage(languageCode)),
        dispatch(getConfigInfoByTenant(REACT_APP_TENANT_ID_FOR_CLIENT)),
        dispatch(getCampaignList()),
        dispatch(getCampaignCategories()),
        dispatch(getReferralInfo(REACT_APP_TENANT_ID_FOR_CLIENT)),
        dispatch(getEarningList()),
        dispatch(getOfferList()),
        dispatch(getOfferCategories()),
        dispatch(getProviderList()),
        dispatch(getUpdateCardList())
      ])
      trackEvent({
        action: 'Selected Language',
        category: 'Language',
        payload: {
          title: 'language',
          description: selectedLanguage,
          value: isChangeLang ? selectedLanguage : 'Default'
        }
      })
    }
  }
  const getSelectedLang = (): string => {
    return selectedTitle === 'English'
      ? selectedLanguage.toUpperCase()
      : t([selectedTitle])
  }
  useEffect(() => {
    if (isOpenLangMenu) {
      setIsChangeLang(false)
      trackEvent({
        action: 'Language',
        category: AnalyticsCategory.Menu
      })
    }
  }, [isOpenLangMenu, trackEvent])
  return (
    <Box className='multi-lang-action'>
      <Box className='multi-lang-name'>
        <Box className='multi-lang-name__selected'>
          <p>{getSelectedLang()}</p>
        </Box>
      </Box>
      <Button
        ref={buttonRef}
        id='multi-lang-button'
        aria-label='Open language menu'
        aria-controls={isOpenLangMenu ? 'language-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpenLangMenu ? 'true' : undefined}
        onClick={handleClickMenu}
        tabIndex={0}
        variant='text'
        sx={{
          minWidth: 'auto',
          padding: '8px',
          marginLeft: '4px',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <KeyboardArrowDownIcon style={{ color: 'white' }} />
      </Button>
      <Menu
        id='language-menu'
        anchorEl={buttonRef.current}
        open={isOpenLangMenu}
        onClose={handleClickMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          className: 'dropdown'
        }}
      >
        {languageList != null &&
          Object.values(languageList).map((lang, index) => (
            <MenuItem
              key={lang.LanguageName}
              value={lang.LanguageCode}
              title={lang.LanguageName}
              className={`lang-menu-item ${
                selectedLanguage === lang.LanguageCode ? 'selected-lang' : ''
              }`}
              onClick={async () => await handleSave(lang.LanguageCode)}
            >
              {t([lang.LanguageName])}
              <span>
                <CheckIcon
                  className={`check-icon ${
                    selectedLanguage === lang.LanguageCode
                      ? 'show-icon'
                      : 'hide-icon'
                  }`}
                />
              </span>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  )
}

export default MultiLanguageDropDown
