import { FC } from 'react'

import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import './SectionTitle.scss'

interface SectionTitleProps {
  title: string
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}

const SectionTitle: FC<SectionTitleProps> = ({
  title,
  Icon
}) => {
  return (
    <div className='featured__preview__title'>
      {Icon != null && <Icon className='featured__preview__ico m-right-5' />}
      <span>{title}</span>
    </div>
  )
}

export default SectionTitle
