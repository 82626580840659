import React, { createElement, FC } from 'react'
interface DynamicIconProps {
  iconName: string
  className?: string
}

const DynamicIcon: FC<DynamicIconProps> = ({ iconName, className }) => {
  let HeroIcons

  try {
    HeroIcons = require('@heroicons/react/24/outline')
  } catch (error) {
    return <></>
  }

  const HeroIcon = HeroIcons[iconName as keyof typeof HeroIcons]

  if (HeroIcon === undefined) {
    return <></>
  }

  return createElement(HeroIcon, { className })
}

export default DynamicIcon
