import React, { ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import '../SignIn/InvalidCredentialsDialog.scss'
import { useSelector } from 'react-redux'
import { selectConfig } from '../../config/selectors'
import _ from 'lodash'

interface PasswordExpiredDialogProps {
  open: boolean
  handleClose: () => void
  handleResetPassword: () => void
}

const PasswordExpiredDialog = ({
  open,
  handleClose,
  handleResetPassword
}: PasswordExpiredDialogProps): ReactElement => {
  const { t } = useTranslation()
  const config: ConfigState = useSelector(selectConfig)

  const hasSupportInfo =
    !_.isEmpty(config.SupportPhoneNumber) ||
    !_.isEmpty(config.CustomerSupportLink)

  const supportInfo =
    !_.isEmpty(config.SupportPhoneNumber)
      ? config.SupportPhoneNumber
      : !_.isEmpty(config.CustomerSupportLink)
        ? config.CustomerSupportLink
        : ''

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='invalid-credentials-dialog'
      maxWidth='xs'
    >
      <ErrorOutlineOutlinedIcon className='info-icon' color='error' fontSize='large' />
      <DialogTitle className='title'>
        <button onClick={handleClose} aria-label='Close' className='close'>
          <CloseOutlinedIcon />
        </button>
        {t`PasswordExpiryDialogTitle`}
      </DialogTitle>
      <DialogContent className='content'>
        {t`PasswordExpiryDialogContent`}
      </DialogContent>
      <DialogActions>
        <Button
          id='reset-password'
          className={`btn dialog-btn ${!hasSupportInfo ? 'm-bottom-2' : ''}`}
          onClick={handleResetPassword}
        >
          {t`ResetPasswordBtn`}
        </Button>
      </DialogActions>
      {hasSupportInfo && (
        <DialogContent className='support-content'>
          {t`CustomerSupportInfo`}
          <span className='highlight'>{supportInfo}</span>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default PasswordExpiredDialog
