import React, { FC, ReactNode } from 'react'
import track from 'react-tracking'

import { analytics } from './analytics'

interface TrackAnalyticsProps {
  children: ReactNode
}

const TrackAnalytics: FC<TrackAnalyticsProps> = ({ children }) => {
  return <>{children}</>
}

export default track({}, {
  dispatch (data: Trackables): any {
    analytics.track(data.action, data).then(
      () => {},
      () => {}
    )
  }
})(TrackAnalytics)
