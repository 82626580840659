import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import { RedemptionCenterCondition } from '../../../redemptionCenterCondition'
import BaseDrawer from '../../../../ui/BaseDrawer/BaseDrawer'
import { IdList } from '../../../../core/utils/id-list'
import SuccessIco from '../../../../ui/customIcons/SuccessIco'
import LoadingDialog from '../../../../ui/LoadingDialog/LoadingDialog'

import './PhoneVerificationSuccess.scss'

interface PhoneVerificationSuccessProps {
  isSpinner: boolean
  isPhoneVerificationSuccessOpen: boolean
  handleClosePhoneVerificationSuccess: () => void
}

const PhoneVerificationSuccess: FC<PhoneVerificationSuccessProps> = ({ isSpinner, isPhoneVerificationSuccessOpen, handleClosePhoneVerificationSuccess }) => {
  const { t } = useTranslation()
  return (
    <BaseDrawer
      isOpen={isPhoneVerificationSuccessOpen}
      onClose={handleClosePhoneVerificationSuccess}
      isCentered
      centerCondition={RedemptionCenterCondition.both}
    >
      <LoadingDialog isSpinner={isSpinner}>
        <div className='phone-verification-success'>
          <div className='phone-verification-success__img'><SuccessIco /></div>
          <div className='phone-verification-success__title'>{t`SuccessfullyVerifiedYour`}</div>
          <div className='phone-verification-success__title'>{t`phoneNumber`}</div>
          <Button
            id={IdList.phoneVerificationSuccess}
            onClick={handleClosePhoneVerificationSuccess}
            className='phone-verification-success__btn'
          >
            {t`Ok`}
          </Button>
        </div>
      </LoadingDialog>
    </BaseDrawer>
  )
}

export default PhoneVerificationSuccess
