import React, { FC } from 'react'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'
import { IdList } from '../../../../core/utils/id-list'

import './WaitingMsg.scss'

interface WaitingDialogProps {
  handleOkBtn: () => void
}

const WaitingMsg: FC<WaitingDialogProps> = ({ handleOkBtn }) => {
  const { t } = useTranslation()

  return (
    <div className='waiting-dialog'>
      <HourglassEmptyIcon className='waiting-dialog__ico' />

      <div className='waiting-dialog__msg'>{t`WaitingDialogMsg`}</div>

      <Button
        id={IdList.waitingDialogBtn}
        onClick={() => handleOkBtn()}
        className='btn waiting-dialog__btn'
      >
        {t`Ok`}
      </Button>
    </div>
  )
}

export default WaitingMsg
